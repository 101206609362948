@charset "UTF-8";
body {
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
}

header {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(/images/home.jpg);
  background-position: center;
  background-size: cover;
  color: #fff;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  header {
    margin-bottom: 2rem;
  }
}
.darken {
  /* csslint ignore:start */
  background-blend-mode: multiply;
  /* csslint ignore:end */
  transition: background 0.25s;
}

.undarken {
  /* csslint ignore:start */
  background-blend-mode: normal;
  /* csslint ignore:end */
  transition: background 0.25s;
}

section {
  margin-bottom: 2rem;
}

.hometron {
  bottom: 0;
  left: 0;
  margin: 3rem;
  position: absolute;
  text-shadow: 2px 2px 4px #333;
  width: 50%;
}

.hidden {
  opacity: 0;
  transition: opacity 0.25s;
}

.visible {
  opacity: 1;
  transition: opacity 0.25s;
}

.pagetron {
  margin-bottom: 2rem;
  position: relative;
}

.pagetron-heading {
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 1rem;
  position: absolute;
  text-shadow: 2px 2px 4px #333;
}

@media (min-width: 992px) {
  .pagetron-heading {
    padding: 3rem;
  }
}
.display-4 {
  font-size: 2rem;
}

@media (min-width: 992px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.navbar {
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  z-index: 1;
}

.navbar .navbar-brand {
  border-style: solid;
  border-width: 4px;
  padding: 4px 6px;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-toggler {
  border-color: #fff;
  color: #fff;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (min-width: 992px) {
  .navbar .nav-item {
    border-color: #fff;
    border-style: solid;
    border-width: 1px;
    margin: 1px;
    padding: 2px 4px;
  }
}
.navbar-nav .icon-link {
  display: inline-block;
  padding-right: 1rem;
}

.navbar-nav .icon-link .icon-songkick {
  font-size: 1.33333em;
  vertical-align: text-bottom;
}

.navbar-nav .icon-link .icon-songkick::before {
  color: #fff;
}

footer {
  background-color: #212529;
  color: #fff;
  font-size: 0.8rem;
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.blockquote {
  border-left: 0.25rem solid #ccc;
  padding-left: 1rem;
  text-align: left;
}

.card {
  border-radius: 0;
}

.citation {
  color: #6c757d;
  font-style: italic;
  text-align: right;
}
.citation::before {
  content: "— ";
}

.external-media {
  margin-bottom: 1rem;
}

.embed-responsive {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .embed-responsive-1by1 {
    margin-bottom: 0;
  }
}